/**
 * @module auth
 * @description Authenticated user model. Created to isolate other modules from the global variable `window.currentVisitor`.
 */

/**
 * @typedef {object} CurrentVisitor
 * @property {null|number|string} id undocumented
 * @property {string} name undocumented
 * @property {?string} slug undocumented
 * @property {?string} guard undocumented
 * @property {Function} isGuest Determine if the current user is a guest.
 */

/** @type {CurrentVisitor} */
const currentVisitor = window.currentVisitor;

/**
 * @typedef {object} Member
 * @property {number} id undocumented
 * @property {string} name undocumented
 * @property {string} slug undocumented
 * @property {"member"} guard undocumented
 */

/**
 * @typedef {object} Mentor
 * @property {number} id undocumented
 * @property {string} name undocumented
 * @property {string} slug undocumented
 * @property {"mentor"} guard undocumented
 */

/** Created to provide API similar to Laravel's Auth Facade. */
export const Auth = {
    /**
     * Determine if the current user is a guest.
     * @returns {boolean}
     */
    guest() {
        return currentVisitor.id === null;
    },

    /**
     * Determine if the current user is logged in.
     * @returns {boolean}
     */
    check() {
        return !this.guest();
    },

    /**
     * Get user id
     * @returns {number|string|null}
     */
    id() {
        return currentVisitor.id;
    },

    /**
     * Get the current User. Null otherwise.
     * @returns {Member|Mentor|null}
     */
    user() {
        return currentVisitor.id ? {
            id: currentVisitor.id,
            name: currentVisitor.name,
            slug: currentVisitor.slug,
            guard: currentVisitor.guard,
        } : null;
    },
};
